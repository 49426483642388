import './App.css';
import ModelViewer from './ModelViewer';
import Ticket from './Ticket';
import React, { useState } from 'react';

function App() {
  const [showTickets, setShowTickets] = useState(false);
  const [shrinkGift, setShrinkGift] = useState(false);
  const [showText, setShowText] = useState(false);

  const handleGiftClick = () => {
    setShrinkGift(true);

    setTimeout(() => {
      setShowTickets(true);
    }, 7000);

    setTimeout(() => {
      setShowText(true);
    }, 12000);
  };

  return (
    <div className="App">
      <div className={`GiftContainer ${shrinkGift ? "shrink" : ""}`}>
        <ModelViewer
          modelPath="/gift/"
          modelFile="scene.gltf"
          onGiftClick={handleGiftClick}
        />
      </div>

      <div className={`text ${showText ? "animate" : ""}`}>
        <p>Hlakka til að fara með þér til Florence í júlí. <br /> Gleðileg jól❤️</p>
      </div>

      <div className={`TicketsContainer ${showTickets ? "animate" : ""}`}>
        <div className="ticket">
          <Ticket name={"HARALDUR FRIÐRIKSSON"} seat={"9B"} />
        </div>
        <div className="ticket">
          <Ticket name={"ÞÓRDÍS BJÖRNSDÓTTIR"} seat={"9A"} />
        </div>
      </div>
    </div>
  );
}

export default App;
